@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  /* Ensure the body handles text direction */
  direction: ltr; /* Default to LTR */
}

/* Apply RTL direction if the html tag has 'dir' attribute set to 'rtl' */
[dir='rtl'] {
  direction: rtl;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}


/* Add this to your CSS file if necessary */
.dropdown-button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: #e5e7eb; /* Tailwind Gray-200 */
  color: #374151; /* Tailwind Gray-800 */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #d1d5db; /* Tailwind Gray-300 */
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  top: 100%;
  right: 0;
  z-index: 10;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  display: block;
  text-align: left;
  width: 100%;
  color: #374151; /* Tailwind Gray-800 */
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f3f4f6; /* Tailwind Gray-100 */
}

.dropdown-item-active {
  background-color: #1d4ed8; /* Tailwind Blue-900 */
  color: white;
}


/* Add these styles to your CSS file */

/* General styles for navigation links */
.nav-link {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: #6b7280; /* Tailwind Gray-500 */
  transition: color 0.3s;
  text-decoration: none;
}

/* Hover effect */
.nav-link:hover {
  color: #1d4ed8; /* Tailwind Blue-900 */
}

/* CTA link styles */
.cta-link {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: white;
  background-color: #1d4ed8; /* Tailwind Blue-900 */
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem; /* Tailwind rounded-xl */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

/* CTA hover effect */
.cta-link:hover {
  background-color: #1e40af; /* Tailwind Blue-800 */
}

/* Adjust spacing for RTL and LTR layouts */
body[dir='ltr'] .nav-link {
  margin-left: 1rem;
}

body[dir='rtl'] .nav-link {
  margin-right: 1rem;
}

body[dir='rtl'] .cta-link {
  margin-right: 1rem;
}

body[dir='ltr'] .cta-link {
  margin-left: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .nav-link {
    margin: 0.5rem;
  }

  .cta-link {
    padding: 0.5rem 1rem;
  }
}
